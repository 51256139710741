/*
 * Permission Connector
 * https://mam-confluence.1and1.com/x/ZnVLC
 *
 * The Permission Connector serves as middleman to allow an easier integration of the PPP-Client, TCF_API and Permission Tracking
 */
if ( typeof window.CustomEvent !== "function" ) {
    window.CustomEvent = (event, params) => {
        params = params || {bubbles: false, cancelable: false, detail: null};
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

        return evt;
    };
}


/**
 * This function tries to give back the value of a URL Parameter
 * @param  {string}
 * @return {string}
 */
const get_url_params = (url) => {
    let vars = {};
    let parts = ('' + url).replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    return vars;
}


const get_location_as_split = () => {
    return window.location.host.replace('.server.lan', '').split('.');
}

// Get top level domain
const canonical = document.querySelector('[rel="canonical"]');
let split = [];

if (canonical) {
    // remove web.cent because it messes with the splitting
    split = canonical.getAttribute('href').replace('web.cent', '').split('.');
    split[0] = split[0].replace('https://', '').replace('http://', '')

    if (split[split.length - 1].indexOf('/') >= 0) {
        split[split.length - 1] = split[split.length - 1].substring(0, split[split.length - 1].indexOf('/'));
    }
} else {
    split = get_location_as_split();
}

// brand_index: How far from the end of the array is the brand saved
// is different for gmx.net and gmx.co.uk
let domain = '';
let brand_index = 0;
let count_since_brand = 0;
let found_brand = false;

for (let i = 0; i < split.length; i++) {
    if(['gmx', 'web', 'mail', '1und1'].includes(split[i])) {
        brand_index = i;
        found_brand = true;
    }

    if (found_brand) {
        ++count_since_brand;
    }
}

if (count_since_brand <= 2) {
    domain = `${split[brand_index]}.${split[brand_index + 1]}`;
} else {
    domain = `${split[brand_index]}.${split[brand_index + 1]}.${split[brand_index + 2]}`;
}

// In case the domain isn't a brand domain (provided by PPP) the default Domain will be 'web.de'
// refactor to use Array.some(element => otherArray.includes(element))
if (!['gmx', 'web', 'mail', '1und1'].includes(split[brand_index])) {
    domain = 'web.de';
}

// Specific Domains shall not get the permission client or rather anything at all
const in_blacklist = false;/*(['netid'].includes(split[split.length - 2]));*/

// get the middleman script url
const PERMISSION_SCRIPT_SRC = (document.querySelector('script[src*="connector"]') || {}).src;

if (!PERMISSION_SCRIPT_SRC) {
    throw 'The script was not found in DOM.';
}

// get passed parameter and tdl from host
const URL_PARAMS            = get_url_params(PERMISSION_SCRIPT_SRC);

const STAGE_PERMISSION = (PERMISSION_SCRIPT_SRC.indexOf('/src/') > -1 || PERMISSION_SCRIPT_SRC.indexOf('/qa/') > -1) ? 'qa' : 'latest';
const STAGE_PPP = STAGE_TCF_API = (STAGE_PERMISSION == 'qa') ? 'qa' : 'live';

const VERSION_PPP_CLIENT    = 1;
const VERSION_TCF_API       = 1;
const VERSION_TCF_API_CALL  = 2;
const VERSION_PERMISSION_TRACKING = STAGE_PERMISSION; // Stage is equal version

// Definition of all the need URLs + STAGES & VERSION
const PATH_PPP_CLIENT        = `https://dl.${domain}/permission/${STAGE_PPP}/v${VERSION_PPP_CLIENT}/ppp/js/permission-client.js`;
const PATH_PPP_CLIENT_COMBAT = `https://dl.${domain}/permission/${STAGE_PPP}/v${VERSION_PPP_CLIENT}/ppp/js/permission-client-compat.js`;
const PATH_PPP_POLYFILLS     = `https://dl.${domain}/permission/${STAGE_PPP}/v${VERSION_PPP_CLIENT}/ppp/js/polyfills/promise.min.js`;
const PATH_TFC_API           = `https://dl.${domain}/tcf/${STAGE_TCF_API}/v${VERSION_PPP_CLIENT}/js/tcf-api.js`;
const PATH_TRACKING          = `https://js.ui-portal.de/prompt/permission/${VERSION_PERMISSION_TRACKING}/tracking.min.js`;

// lets get parameter 'exclude' for later use
const url_param_excludes = URL_PARAMS['exclude'] || '';

const SWITCH_PPP_CLIENT     = !url_param_excludes.includes('ppp');
const SWITCH_TCF_API        = !url_param_excludes.includes('tcf');
const SWITCH_TRACKING       = !url_param_excludes.includes('tracking');

// Define permission features to be requested
const permissionFeatures           = ['brainTracking', 'googleAdRemarketing', 'googleAdsConversionTracking']

// Initialize the permission client
const init_permission_client = () => {
    // add permission client container
    const container = document.createElement('div');
          container.classList.add('permission-layer-container');

    document.getElementsByTagName('body')[0].appendChild(container);

    /*
        Mapping to the right portal syntax
        For more information, check the technical concept [design decision 7.2|https://mam-confluence.1and1.com/pages/viewpage.action?pageId=139162982#PermissionConnector-7.2Parameter"portal"inconsistentstructure]
    */
    const portal_mapping = {
        'gmxde': 'gmx',
        'gmxnet': 'gmx',
        '1und1de': '1und1'
    };

    // create data object for init function
    const data = {
        portal: (URL_PARAMS['portal'] || '').replace(/[^a-z0-9]/gi, ''),
        category: URL_PARAMS['category'] || 'permission',
        layerContainer: '.permission-layer-container',
        consentParameters: {
            lang: document.documentElement.lang || 'de',
            tcf: '2',
            tcfLayerMode: true
        },

        getModel: () => {
            // permission model stored on localStorage
            const permModel = PermissionClient.DEFAULT_HANDLER.getModel();
            // sync Permission model with consentLevel
            // consentLevel is leading here
            return window.PermissionClientCompat.computeModelByConsentLevel(permModel);
        },

        // Reminder purpose; Unclear if we need it
        getIdentity: () => {
        },

        onChange: model => {
            const computedConsentLevel = window.PermissionClientCompat.computeConsentLevelByModel(model);

            window.PermissionClientCompat.setConsentLevel(computedConsentLevel);
            window.PermissionClient.DEFAULT_HANDLER.onChange(model);
        },

        // Reminder purpose; Unclear if we need it
        onResizeLayer: dimensions => {
        },

        onComplete: () => {
            window.PermissionClientCompat.setConsentLevel('2');
            window.PermissionClientCompat.updateModelByConsentLevel();
            window.PermissionClient.DEFAULT_HANDLER.onComplete();

            document.querySelector('.permission-layer-container')?.remove();
        },

        // Reminder purpose; Unclear if we need it
        onShowLayer: () => {
        }
    };

    // No portal parameter is provied use domain instead
    if (!data.portal) {
        let tld = location.host.split('.');
            tld = (tld.includes('co') && tld.includes('uk')) ? tld.splice(-3) : tld.splice(-2);

        data.portal = tld.join('').replace(/[^a-z0-9]/gi, '');
        data.portal = portal_mapping[data.portal] || data.portal;

        // if portal is any domain, which isn't in the brand list, the fallback values will be set
        if (!['gmx', 'gmxat', 'gmxch', 'gmxfr', 'gmxes', 'gmxcom', 'gmxcouk', '1und1', 'webde', 'mailcom'].includes(data.portal)) {
            data.portal = 'permission';
        }
    } else {
        data.portal = portal_mapping[data.portal] || data.portal;
    }

    // No section parameter is provided; use default value 'other'
    data.section = URL_PARAMS['section'] || ((data.category === 'permission') ? 'other' : false);
    if (!data.section) {
        delete data.section;
    }

    // Includes PPP-Client Polyfill for IE11
    if (typeof window.Promise !== 'function') {
        const script_polyfill = document.createElement('script');
              script_polyfill.src = PATH_PPP_POLYFILLS;

        script_polyfill.addEventListener('load', () => {
            // We give IE (a tick) time, before we trigger the PermissionClient
            setTimeout(() => {
                window.PermissionClientCompat.init(window.PermissionClient, {
                    cookieDomain: domain,
                });
                window.PermissionClient.init(data);
            }, 2);
        });

        document.getElementsByTagName('head')[0].appendChild(script_polyfill);
    } else {
        window.PermissionClientCompat.init(window.PermissionClient, {
            cookieDomain: domain,
        });
        window.PermissionClient.init(data);
    }
    
    // Register links with defined selector to manually open the tcf layer with saved settings
    document.querySelectorAll('a[href="#trigger-permission-layer"]')?.forEach(function(trigger) {
        trigger.addEventListener('click', (evt) => {
            evt.preventDefault();
            data.forcePermissionLayer = 'tcf_v2';
            data.consentParameters.tcfLayerMode = 'resurface';            
            window.PermissionClient.init(data);
        });
    });
}

if (!in_blacklist) {
    /**
     * Constent Object for tracking
     * @type {object}
     */
    window.consent = {
        features: {},
        full: false // Privacy by Default
    };

    const include_ppp = () => {
        if (SWITCH_PPP_CLIENT) {
            // get the permission script
            const script_ppp = document.createElement('script');
            script_ppp.src = PATH_PPP_CLIENT;
            script_ppp.defer = true;

            // get the permission compat script
            const script_ppp_compat = document.createElement('script');
            script_ppp_compat.src = PATH_PPP_CLIENT_COMBAT;
            script_ppp_compat.defer = true;

            document.getElementsByTagName('head')[0].appendChild(script_ppp);
            document.getElementsByTagName('head')[0].appendChild(script_ppp_compat);

            window.addEventListener('load', init_permission_client);
        }
    }

    const include_tracking = () => {
        if (SWITCH_TRACKING) {
            /**
             * Lets set the needed Consent or get Fallback
             * We do this check here, because our Tracking needs it for Brain and it is possible somebody excluded TCF_API
             */
            if (window.consent?.full) {
                // Full consent? No need for Brain
                permissionFeatures.forEach((permissionFeature) => {
                    window.consent.features[permissionFeature] = window.consent.full;
                });
            } else {
                try {
                    window.__tcfapi('addEventListener', VERSION_TCF_API_CALL, function() {
                        permissionFeatures.forEach((permissionFeature) => {
                            window.__tcfapi('getPermission', VERSION_TCF_API_CALL, function(valid) {
                                if (valid === null || valid === undefined) {
                                    valid = false;
                                }

                                window.consent.features[permissionFeature] = valid;
                            }, permissionFeature);
                        });
                    });
                } catch (e) {
                    permissionFeatures.forEach((permissionFeature) => {
                        window.consent.features[permissionFeature] = false;
                    });
                }
            }

            // get the tcf api script
            const script_tracking = document.createElement('script');
            script_tracking.src = PATH_TRACKING;
            script_tracking.defer = true;

            document.getElementsByTagName('body')[0].appendChild(script_tracking);
        }
    }

    // get the tcf api script
    if (SWITCH_TCF_API) {
        let timeout = false;
        const script_tcf = document.createElement('script');
        script_tcf.src = PATH_TFC_API;

        const include_scripts = () => {
            include_ppp();
            include_tracking();
        }

        const fire_tcf_load_event = ({success, timeout}) => {
            if (STAGE_PERMISSION == 'qa') { console.log(`tcfLoad: fired (success: ${success}, timeout: ${timeout})`); }

            const tcfLoad = new CustomEvent('tcfLoad', {
                detail: {
                    bubbles: true,
                    success: success,
                    timeout: timeout
                }
            });

            document.dispatchEvent(tcfLoad);
        }

        const timer = setTimeout(() => {
            timeout = true;
            fire_tcf_load_event({success: false, timeout: true});
        }, 6000);

        // load other scripts as soon as TCF is available
        script_tcf.onload = () => {
            if (!timeout) {
                clearTimeout(timer);

                if (STAGE_PERMISSION == 'qa') { console.log('tcfLoad: loaded'); }

                // Lets check if we got full Constent
                try {
                    window.__tcfapi('addEventListener', 2, function () {
                        window.__tcfapi('getPermission', 2, function (valid) {
                            if (valid === null || valid === undefined) {
                                valid = false;
                            }
                            window.consent.full = valid;
                        }, 'fullConsent');
                    });
                } catch (e) {
                }

                fire_tcf_load_event({success: true, timeout: false});
                include_scripts();
            }
        }

        script_tcf.onerror = () => {
            if (!timeout) {
                clearTimeout(timer);
                if (STAGE_PERMISSION == 'qa') { console.log('tcfLoad: error'); }

                fire_tcf_load_event({success: false, timeout: false});
                include_scripts();
            }
        }

        window.addEventListener('DOMContentLoaded', () => {
            document.getElementsByTagName('head')[0].appendChild(script_tcf);
        });
    } else {
        include_ppp();
        include_tracking();
    }
}

